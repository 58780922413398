import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import {
  getDatabase,
  ref,
  onValue,
  off,
  get,
  query,
  orderByChild,
  equalTo,
  push,
  update,
  set,
  remove, limitToFirst
} from 'firebase/database';
import { getAuth } from 'firebase/auth';
import {showConfirmPopup, showErrorPopup, showInfoPopup, showToast} from '../utils/SwalConfig';
import ReactCountdownClock from 'react-countdown-clock';
import DatePicker from '../utils/DatePicker';
import ReactPlayer from 'react-player';
import '../../styles/css/AdminMain.css';
import {
  cleanCSVText,
  convertTimeStampToHumanReadable,
  findFirstAndLastName,
  detectHowManyCodesEntered,
  cleanDateForSelectDropdown
} from '../utils/HelpfulFunction';
import {appDatabasePrimaryFunctions} from "../../base";

class SetUpGame extends Component {
  constructor(props) {
        super(props);
        this.state = {
          racer_select: {},
          ticket_select: {},
          ticketList: [],
          users: [],
          racerList: [],
          schedule_game_time: new Date(),
          schedule_game: false,
          racersAdded: [],
          losingRewardsAdded: [],
          emailVariables: {},
          videoState: 0,
          rewardsAdded: [],
          rewardToAdd: '',
          rewardAmount: 0,
          codes: false,
          codesArray: '',
          qrCodes: false,
          userPicks: [],
          tenantRules: {},
          activateGame: {},
          currentGameRewards: [],
          currentGame: null,
          modal: false,
          gameName: null,
          userGameHistory: [],
          loading: true,
          wizardLocation: 'first',
          showStats: true,
          advanceSettings: false,
          sentStatsEmail: false,
          gamesList: [],
          emailsToSend: {},
          randomOrder: false,
          losingRandomOrder: false,
          showWinning: true,
          connected: false
        };
        this.db = getDatabase();
        this.auth = getAuth();
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRacerVideo = this.toggleRacerVideo.bind(this);
        this.togglePickRacerModal = this.togglePickRacerModal.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData() {
    const idKeyRef = ref(this.db, 'id_key');
    get(idKeyRef).then((snapshot) => {
      if (snapshot.exists()) {
        this.setState({ id_key: snapshot.val() });
      }
    });

    const racerListRef = ref(this.db, 'racerList');
    onValue(racerListRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const racerList = Object.keys(data).map((key) => {
          return { id: key, ...data[key] };
        });
        this.setState({ racerList });
      } else {
        this.setState({ racerList: [] });
      }
    });

    const emailVariablesRef = ref(this.db, 'emailVariables');
    onValue(emailVariablesRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ emailVariables: snapshot.val() || {} });
      }
    });

    const tenantRulesRef = ref(this.db, 'tenantRules');
    onValue(tenantRulesRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ tenantRules: snapshot.val() || {} });
      }
    });

    const tenantVariablesRef = ref(this.db, 'tenantVariables');
    onValue(tenantVariablesRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ tenantVariables: snapshot.val() || {} });
      }
    });

    const userAnswersRef = ref(this.db, 'userPicks');
    onValue(userAnswersRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ userAnswerCount: snapshot.size });
      } else {
        this.setState({
          userAnswerCount: 0,
          userPicks: []
        });
      }
    });

    const currentGameRef = ref(this.db, 'currentGame');
    onValue(currentGameRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ currentGame: snapshot.val(), loading: false }, () => {
          this.setGameToWatch(snapshot.val().id);
          this.getRedemptions(snapshot.val().id);
        });
      }
    });

    const ticketListRef = ref(this.db, 'rewardsList');
    onValue(ticketListRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const ticketList = Object.keys(data).map((key) => {
          return { id: key, ...data[key] };
        });
        this.setState({ ticketList });
      } else {
        this.setState({ ticketList: [] });
      }
    });


    const connectedRef = ref(this.db, '.info/connected');
    onValue(connectedRef, (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ connected: snapshot.val() });
      }
    });
  }

  async getRedemptions(gameId) {
    if (!gameId) return;
    const redeemedPrizeRef = query(ref(this.db, 'redeemedPrize'), orderByChild('gameId'), equalTo(gameId));
    onValue(redeemedPrizeRef, (snapshot) => {
      let countOfWinners = 0;
      snapshot.forEach((childSnapshot) => {
        if (childSnapshot.val().redeemed) {
          countOfWinners++;
        }
      });
      this.setState({ redemptions: countOfWinners });
    });
  }

  downloadUsers(){
      this.setState({loading: true});
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const vm = this;
      let csv = 'Email,First Name,Last Name,Zip Code,Birthday,Opt-In,Pick,Time Picked,Reward,Code,Has been Redeemed\n';
      if(isMlbApp){
        csv = 'Okta,First Name,Last Name,Zip Code,Birthday,Opt-In,Pick,Time Picked,Reward,Code,Opens,Has been Redeemed\n';
      }
      Promise.all([vm.getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
        let users = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          users = snapshots[0].val();
        }
        const currentUsersState = await vm.sortPlayersForAdmin(null, null, appDatabasePrimaryFunctions, vm.state.currentGame, isMlbApp);
        currentUsersState.forEach(function(row) {
          let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
          csv += (row.email || row.uid || "");
          csv+= ",";
          csv+= (cleanCSVText(firstName) || "");
          csv+= ",";
          csv+= (cleanCSVText(secondName) || "");
          csv+= ",";
          csv+= (cleanCSVText(users[row.uid].zipCode) || "");
          csv+= ",";
          csv+= (users[row.uid].birthday || "");
          csv+= ",";
          csv+= (users[row.uid].optIn || "");
          csv+= ",";
          csv+= (cleanCSVText(row.pick) || "");
          csv+= ",";
          csv+= convertTimeStampToHumanReadable(row.timeStamp);
          csv+= ",";
          csv+= (cleanCSVText(row.rewardSent) || "NONE");
          csv+= ",";
          csv+= (cleanCSVText(row.code) || "-");
          if(isMlbApp){
            csv+= ",";
            csv+= (row.opens || "0");
          }
          csv+= ",";
          csv+= row.isRedeemed ? "Yes" : "No";
          csv += "\n";
        }, this);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_game_users_" + date_got;
        hiddenElement.click();
        vm.setState({loading: false});
      })
    }

  setGameToWatch(id) {
    if (this.userGameHistoryRef) {
      off(this.userGameHistoryRef);
    }
    if (id) {
      const userGameHistoryRef = query(ref(this.db, 'userGameHistory'), orderByChild(id), equalTo(id));
      this.userGameHistoryRef = userGameHistoryRef;
      onValue(userGameHistoryRef, (snapshot) => {
        this.setState({ userGameHistory: snapshot.size });
      });
    }
  }

  componentWillUnmount() {
    // Clean up Firebase listeners
    off(ref(this.db, 'racerList'));
    off(ref(this.db, 'currentGame'));
    off(ref(this.db, 'rewardsList'));
    off(ref(this.db, 'emailVariables'));
    off(ref(this.db, 'tenantRules'));
    off(ref(this.db, 'userPicks'));
    if (this.userGameHistoryRef) {
      off(this.userGameHistoryRef);
    }
    off(ref(this.db, '.info/connected'));
  }

  alertAboutConnectivity() {
    showErrorPopup('Something went wrong! Check your internet connection and try again!', true);
  }

  async sortAndPrepareToSendEmail(){
      const isMlbTeam = process.env.REACT_APP_IS_MLB_TEAM === 'true';
      const isDunkin = process.env.REACT_APP_GAME_VERSION==="dunkin";
      const applicationName = "MascotRace/1.0";
      this.setState({
        loading: true
      })
      if(isDunkin){
        const userPicksRef = ref(this.db, 'userPicks');
        const userPicksSnapshot = await get(userPicksRef);
        const userPicks = userPicksSnapshot.val() || {};
        const currentGame = this.state.currentGame || {}
        const winnerIds = [];
        const winningRacerId = currentGame.winner;
        //also used when setting winner
        if(winningRacerId === "multiple"){
          for(const racerIndex in currentGame.racers){
            if(currentGame.racers[racerIndex] && currentGame.racers[racerIndex].winner){
              winnerIds.push(currentGame.racers[racerIndex].id);
            }
          }
        } else {
          winnerIds.push(winningRacerId)
        }
        let usersToEmail = [];
        const losersToEmail = [];
        for(var pickId in userPicks){
          var userPick = userPicks[pickId]
          if(userPick.pickid && winnerIds.length > 0 && winnerIds.indexOf(userPick.pickid) !== -1){
            usersToEmail.push({id: userPick.uid, timePicked: userPick.timeStamp})
          } else {
            if(currentGame.sendLosingEmails || (currentGame.losingRewards && currentGame.losingRewards.length > 0)) {
              losersToEmail.push({id: userPick.uid, timePicked: userPick.timeStamp})
            }
          }
        }
        this.sendDunkinEmail(usersToEmail, winnerIds, currentGame.id, losersToEmail);
        return
      }
      const vm = this;
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendPrizesWithLosingPrize`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendPrizesWithLosingPrize`
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({isMlbTeam: isMlbTeam, mlbTeamId: process.env.REACT_APP_MLB_TEAM_ID, fromAdmin: true, applicationName: applicationName})
      }).then(async res => {
        if(res) {
          const value = await Promise.resolve(res.json());
          vm.newHandleEmailResponse(value);
        } else {
            vm.setState({
                loading:false
            })
        }
      }).catch((e)=>{
          console.log(e);
          this.alertAboutConnectivity();
          vm.setState({loading:false})
      })
    }

    newHandleEmailResponse(response){
        this.setState({
          loading: false
        })
        let popUpTitle = "Successfully Sent!";
        let error = false;
        if(!response || response.result === 'no_data'){
          popUpTitle = 'Something went wrong!  Check your internet connection and try again!';
          error = true;
        } else if(response.error) {
          popUpTitle = response.error;
          error = true;
        }

        if(error){
          showErrorPopup('Oh No!', popUpTitle)
          return;
        }

        if(response.result === "finished") {
            const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
            const noEmail = this.state.tenantVariables.doNotCollectEmail;
            if(isMlbApp || noEmail){
                popUpTitle = "Game Ended Successfully"
            }
            showToast(popUpTitle)
            this.endGameAfterEmailsSent();
        }
    }

    handleEmailResponse(response, reward_name){
      if(!response){
        this.alertAboutConnectivity()
        this.setState({
          loading: false
        })
        return
      }
      if(response.message === "Emails sent!" || response.message === "Request received, processing emails in the background."){
        if(this.state.tenantVariables.allowSeasonLongPicks){
          const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
          let successSendText = 'Successfully Sent!';
          if(isDunkin){
            successSendText = 'Emails Scheduled To Send!';
          }
          showToast(successSendText);
        } else {
          const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
          const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
          const noEmail = this.state.tenantVariables.doNotCollectEmail;
          let successText = 'Successfully Sent!';
          if(isMlbApp || noEmail){
            successText = "Game Ended Successfully"
          }
          if(isDunkin){
            successText = "Emails Scheduled To Send!"
          }
          showToast(successText)
        }
        this.saveToEmailsSent(reward_name, response.recipients, response.codes);
      } else {
        showErrorPopup('Oh No!', response.error)
        this.setState({
          loading: false
        })
        return
      }
      this.endGameAfterEmailsSent();
    }

    async endGameAfterEmailsSent() {
      const currentGame = this.state.currentGame;
      const emailVariables = this.state.emailVariables || {};

      // Update local state
      currentGame.videoState = 2;
      currentGame.sentEmail = true;

      this.setState({
        loading: false,
        currentGame: currentGame,
      }, async () => {
        // Update Firebase with the new game state
        const currentGameRef = ref(this.db, `currentGame`);
        try {
          await update(currentGameRef, {
            videoState: currentGame.videoState,
            sentEmail: currentGame.sentEmail
          });

          if (!this.state.sentStatsEmail && emailVariables.statsEmailReceivers) {
            this.sendStatsEmail();
            this.setState({
              sentStatsEmail: true,
            });
          } else {
            const organizeUserAnswers = await this.sortPlayersForAdmin(
                null,
                null,
                appDatabasePrimaryFunctions,
                currentGame,
                process.env.REACT_APP_IS_MLB_TEAM === "true"
            );
            this.setState({
              currentUserPicksState: organizeUserAnswers,
            });
          }
        } catch (error) {
          console.error('Error updating game state in Firebase:', error);
          this.alertAboutConnectivity();
        }
      });
    }

    saveToEmailsSent(rewardName, recipients, codes, doNotCollectEmail=false){
      var gameToSave = {}
      for(var recipeient_index in recipients){
        var rewardToSave = {}
        rewardToSave.name = rewardName
        var codeSent = false
        if(codes){
          codeSent = codes[recipeient_index]
          rewardToSave.code = codeSent
        }
        var email = recipients[recipeient_index]
        var baseEncodedEmail = btoa(email)
        if(doNotCollectEmail && recipients[recipeient_index] && typeof recipients[recipeient_index] === 'object'){
          baseEncodedEmail = recipients[recipeient_index].id;
        }
        gameToSave[baseEncodedEmail] = rewardToSave;
      }
      const emailsSentRef = ref(this.db, 'emailsSent');
      update(emailsSentRef, gameToSave)
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.error("Error updating emailsSent:", error);
        });
    }

    async createGame(){
      if(!this.state.connected){
        this.alertAboutConnectivity();
        return
      }
      const createGameObject = {};
      const gameId = push(ref(this.db)).key;
      const racersAdded = this.state.racersAdded;
      const useStatFeed = this.state.useStatFeed || false;
      const statFeedOption = this.state.statFeedOption || "";
      let rewardsAdded = this.state.rewardsAdded || [];
      let losingRewardsAdded = this.state.losingRewardsAdded || [];
      const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
      const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
      const futureGames = this.state.futureGamesList || [];
      const gameName = this.state.gameName;
      let sendLosingEmails = this.state.sendLosingEmails;
      const startInningScheduleTime = this.state.startInningScheduleTime;
      const endInningScheduleTime = this.state.endInningScheduleTime;
      const endInningSchedulePart = this.state.endInningSchedulePart;
      const startInningSchedulePart = this.state.startInningSchedulePart;
      const gameEndTime = this.state.schedule_game_time_end;
      const dontShufflePicks = this.state.dontShufflePicks;
      const noPrizes = this.state.noPrizes || isDunkin;
      const gameStartTime = this.state.schedule_game_time;
      const scheduledGame = this.state.schedule_game;
      const pickWinner = this.state.pickWinner || false;
      const advancedLosingOptions = this.state.advancedLosingOptions || false;
      let loserSpecialSelect = this.state.loserSpecialSelect;
      if(noPrizes && !isDunkin){
        rewardsAdded = [];
        losingRewardsAdded = [];
        loserSpecialSelect = false;
        sendLosingEmails = false;
      }
      if(!advancedLosingOptions){
          loserSpecialSelect = null;
      }
      let winningRacer = null;
      const currentTimeSeconds = Math.floor(Date.now());
      const inputtedDateSeconds = gameStartTime.getTime();
      let gameEndTimeSeconds = null;
      if(gameEndTime){
        gameEndTimeSeconds = gameEndTime.getTime();
      }
      if(!gameName){
        showInfoPopup("Hold On!", "You must give the game a name!")
        return;
      }
      if(useStatFeed && !statFeedOption){
        showInfoPopup("Hold On!", "You must pick a stat if you want to use the stat feed!")
        return;
      }
      if(scheduledGame){
        if(inputtedDateSeconds <= currentTimeSeconds){
          showInfoPopup("Hold On!", "You have marked that you are scheduling a game but it is before now!")
          return;
        } else if(!inputtedDateSeconds && !startInningScheduleTime) {
          showInfoPopup("Hold On!", "You have marked that you are scheduling but no game start time entered!")
          return;
        } else if(inputtedDateSeconds && startInningScheduleTime) {
          showInfoPopup("Hold On!", "You have both an inning and time picked to start, it can only be one or the other")
          return;
        } else if(gameEndTimeSeconds && endInningScheduleTime){
          showInfoPopup("Hold On!", "You have both an inning and time picked to end, it can only be one or the other")
          return;
        } else if(gameEndTimeSeconds || endInningScheduleTime){
            //Game end time connected
          if(inputtedDateSeconds && gameEndTimeSeconds && inputtedDateSeconds <= gameEndTimeSeconds){
            showInfoPopup("Hold On!", "End time cannot be before start time")
            return;
          }
          if(startInningScheduleTime && endInningScheduleTime){
            if((startInningScheduleTime < endInningScheduleTime) || (startInningScheduleTime === endInningScheduleTime && endInningSchedulePart === startInningSchedulePart)) {
                showInfoPopup("Hold On!", "End time cannot be before start time")
                return;
            }
          }
        }
        for(const fGameIndex in futureGames){
          const futureGame = futureGames[fGameIndex];
          if(inputtedDateSeconds === futureGame.activateGame.performAt){
            showInfoPopup("Hold On!", "You already have a scheduled game at that time!")
            return;
          }
        }
      }
      if(racersAdded && racersAdded.length < 2){
        const textToFill= isPrediction?"predictions":"racers";
        showInfoPopup("Hold On!", `Not enough ${textToFill} added. You need to add at least 2 ${textToFill} to the game!`);
        return;
      }
      for(let racer in racersAdded){
        if(racersAdded[racer].winner && !pickWinner){
          winningRacer = racersAdded[racer].id;
        } else if(racersAdded[racer].winner && pickWinner){
          racersAdded[racer].winner = false;
        }
      }
      if(!winningRacer && !pickWinner && !useStatFeed){
        showInfoPopup("Hold On!", "No winner is selected!");
        return;
      }
      let readyToUploadPrizes = null;
      let hasRewardWithAutoRedemptions = false;
      if(rewardsAdded.length !== 0){
        let totalAmount = 0;
        readyToUploadPrizes = {};
        for(const rewardIndex in rewardsAdded){
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key] = rewardsAdded[rewardIndex].reward;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['amount'] = parseInt(rewardsAdded[rewardIndex].amount);
          totalAmount+=parseInt(rewardsAdded[rewardIndex].amount);
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codes'] = rewardsAdded[rewardIndex].codes;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['pinsArray'] = rewardsAdded[rewardIndex].pinsArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['used'] = 0;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable'] = rewardsAdded[rewardIndex].reward.isRedeemable || false;
          if(readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable']){
            hasRewardWithAutoRedemptions = true;
          }
        }
        readyToUploadPrizes["totalRewards"] = totalAmount;
        readyToUploadPrizes["totalRewardsUsed"] = 0;
        readyToUploadPrizes["randomPrizeOrder"] = this.state.randomOrder;
      }
      let readyToUploadLosingPrizes = null;
      if(losingRewardsAdded.length !== 0){
        let totalAmount = 0;
        readyToUploadLosingPrizes = {};
        for(const losingRewardIndex in losingRewardsAdded){
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key] = losingRewardsAdded[losingRewardIndex].reward;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['amount'] = parseInt(losingRewardsAdded[losingRewardIndex].amount);
          totalAmount+=parseInt(losingRewardsAdded[losingRewardIndex].amount);
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codes'] = losingRewardsAdded[losingRewardIndex].codes;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['qrCodes'] = losingRewardsAdded[losingRewardIndex].qrCodes;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codesArray'] = losingRewardsAdded[losingRewardIndex].codesArray || [];
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['pinsArray'] = losingRewardsAdded[losingRewardIndex].pinsArray || [];
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['used'] = 0;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable'] = losingRewardsAdded[losingRewardIndex].reward.isRedeemable || false;
          if(readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable']){
            hasRewardWithAutoRedemptions = true;
          }
        }
        readyToUploadLosingPrizes["totalRewards"] = totalAmount;
        readyToUploadLosingPrizes["totalRewardsUsed"] = 0;
        readyToUploadLosingPrizes["randomPrizeOrder"] = this.state.losingRandomOrder;
        sendLosingEmails = true;
      }
      if(losingRewardsAdded.length === 0 && rewardsAdded.length === 0 && !noPrizes){
        showInfoPopup("Hold On!", "No prizes have been added and no prizes box is not checked");
        return;
      }
      createGameObject.loserSpecialSelect = loserSpecialSelect;
      createGameObject.racers = racersAdded;
      createGameObject.videoState = 0;
      createGameObject.status = 0;
      createGameObject.sentEmail = false;
      createGameObject.rewards = readyToUploadPrizes;
      createGameObject.losingRewards = readyToUploadLosingPrizes;
      createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
      createGameObject.pickWinner = pickWinner;
      createGameObject.winner = winningRacer;
      createGameObject.gameName = gameName;
      createGameObject.gameType = "race";
      createGameObject.active = false;
      createGameObject.dontShufflePicks = dontShufflePicks || false;
      createGameObject.sendLosingEmails = sendLosingEmails || false;
      createGameObject.statFeedOption = statFeedOption;
      createGameObject.useStatFeed = useStatFeed;
      let activateGame = {};
      let shouldReplaceCurrentGame  = true
      const currentGame = this.state.currentGame || {};
      if(scheduledGame){
        activateGame['performAt'] = inputtedDateSeconds;
        activateGame['status'] = "scheduled";
        createGameObject['activateGame'] = activateGame
        if(currentGame.gameName){
          shouldReplaceCurrentGame = false
        }
      } else {
        createGameObject['activateGame'] = null
      }
      this.setState({
        loading: true,
        sentStatsEmail: false
      })
      createGameObject.timeStamp = new Date().getTime();
      createGameObject.id = gameId;
      if(shouldReplaceCurrentGame){
        const onSaveAndClearSuccess = await this.saveAndClearGame(createGameObject, createGameObject.id);
        if(!onSaveAndClearSuccess){
          this.setState({
            loading: false
          })
          showInfoPopup("Hold On!", 'Failed to clear out old game, please try again');
          return
        }
      } else if(createGameObject.id) {
        const vm = this;
        const futureGameRef = ref(this.db, `futureGamesList/${createGameObject.id}`);
        set(futureGameRef, createGameObject)
        .then(() => {
          vm.setState({
            modal: false,
            loading: false
          });
        })
        .catch((error) => {
          console.error("Error setting future game:", error);
          vm.setState({ loading: false });
        });
      }
      this.setState({
        modal: false,
        loading: false
      })
    }

    async saveAndClearGame(futureGame, futureGameId){
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      this.setState({loading: true});
      let fetchObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({futureGame: futureGame, futureGameKey: futureGameId})
      }
      let response;
      try{
        response = await fetch(url, fetchObject);
        return !(!response || response.error);
      } catch (e) {
        console.log("ERROR: ", e);
        return false;
      }
    }

    async saveDeleteCurrentGameDataReplace(currentGame, activateGame, createGameObject, fromMakeFutureGameCurrent = false) {
      if (currentGame.gameName) {
        currentGame['users'] = this.state.userGameHistory;
        let prizesWon = 0;

        if (currentGame.sentEmail) {
          const emailsToSendRef = ref(appDatabasePrimaryFunctions, 'emailsToSend');
          const emailsSentRef = ref(appDatabasePrimaryFunctions, 'emailsSent');

          const emailsToSendSnapshot = await get(emailsToSendRef);
          const emailsSentSnapshot = await get(emailsSentRef);

          const emailsToSendCount = emailsToSendSnapshot.size;
          const emailsSentCount = emailsSentSnapshot.size;

          if (emailsToSendCount > emailsSentCount) {
            prizesWon = emailsToSendCount || 0;
          } else {
            prizesWon = emailsSentCount || 0;
          }
        }

        const userPicksSnapshot = await get(ref(appDatabasePrimaryFunctions, 'userPicks'));
        const userPicksVal = userPicksSnapshot.val();

        currentGame['prizes'] = prizesWon;
        const winningRacerIds = [];
        for (const racerIndex in currentGame.racers) {
          const racer = currentGame.racers[racerIndex];
          if (racer.winner) {
            winningRacerIds.push(racer.id);
          }
        }

        let correctAnswerCount = 0;
        let totalAnswersCount = 0;
        for (const userPickIndex in userPicksVal) {
          const userAnswer = userPicksVal[userPickIndex];
          if (winningRacerIds.includes(userAnswer.pickid)) {
            correctAnswerCount++;
          }
          totalAnswersCount++;
        }

        currentGame['answers'] = totalAnswersCount;
        currentGame['correctAnswers'] = correctAnswerCount;

        const organizeUserAnswers = await this.sortPlayersForAdmin(
            null,
            null,
            appDatabasePrimaryFunctions,  // Passing db instead of appDatabasePrimaryFunctions
            currentGame,
            process.env.REACT_APP_IS_MLB_TEAM === "true"
        );

        await set(ref(db, `userAnswersHistory/${currentGame.id}`), organizeUserAnswers);
        console.log("User Answers Saved!");
      }

      const futureGameDataSnapshot = await get(ref(appDatabasePrimaryFunctions, 'futureEmailsToSend'));
      const futureGameData = futureGameDataSnapshot.val();

      await remove(ref(appDatabasePrimaryFunctions, 'prizeWon'));
      await remove(ref(appDatabasePrimaryFunctions, 'currentGame/rewards'));
      await remove(ref(appDatabasePrimaryFunctions, 'currentGame/losingRewards'));
      await remove(ref(appDatabasePrimaryFunctions, 'userPicks'));

      const gamesListRef = push(ref(appDatabasePrimaryFunctions, 'gamesList'));
      await set(gamesListRef, currentGame);

      this.setState({
        currentGame: createGameObject,
        currentUserPicksState: [],
        modal: false,
        loading: false
      }, async () => {
        await set(ref(appDatabasePrimaryFunctions, 'emailsToSend'), futureGameData);
        console.log("Future emailsToSend set successfully");

        await set(ref(appDatabasePrimaryFunctions, 'emailsSent'), null);
        console.log("emailsSent reset successfully");

        await remove(ref(appDatabasePrimaryFunctions, 'futureEmailsToSend'));

        if (fromMakeFutureGameCurrent) {
          this.removeGameFromFutureList(createGameObject.id);
        }
      });

      this.getRedemptions(createGameObject.id);
    }

    async makeFutureGameCurrent(gameToSetCurrent){
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      this.setState({loading: true});
      let fetchObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
      }
      let response;
      try{
        response = await fetch(url, fetchObject);
      } catch (e) {
        this.setState({loading: false});
        console.log("ERROR: ", e);
        return false;
      }
      const responseJson = await Promise.resolve(response.json());
      if(responseJson.error){
        this.setState({loading: false});
        showInfoPopup("Hold On!", "Something went wrong, please try again!");
        return;
      }
      const futureGameList = await this.getFutureGames();
      this.setState({
        futureGamesList: futureGameList,
        loading:false
      })
      this.setGameToWatch(gameToSetCurrent.id);
      showToast('Game made current!')
    }

  async removeGameFromFutureList(gameToRemoveId) {
    const futureGameList = [...this.state.futureGamesList];  // Create a copy of the future games list

    for (let gameIndex = 0; gameIndex < futureGameList.length; gameIndex++) {
      const futureGame = futureGameList[gameIndex];

      // If the game id matches the one to remove
      if (futureGame.id === gameToRemoveId) {
        futureGameList.splice(gameIndex, 1);  // Remove the game from the list

        // If a valid game ID is found, remove it from Firebase
        if (gameToRemoveId) {
          const db = getDatabase();  // Initialize Firebase Database
          const gameRef = ref(db, `futureGamesList/${gameToRemoveId}`);

          try {
            this.setState({loading: true})
            await remove(gameRef);  // Remove the game from Firebase
            showToast('Future game deleted!')
          } catch (error) {
            console.error("Error removing game from Firebase:", error);
          }
        }
      }
    }

    // Update the state with the modified future games list
    this.setState({
      futureGamesList: futureGameList,
      loading: false
    });
  }

  async stopVideo(){
    const copyCurrentGame = this.state.currentGame;
    if (!copyCurrentGame || !copyCurrentGame.timeStamp) return;
    const currentGameRef = ref(this.db, `currentGame`);
    try {
      await update(currentGameRef, {
        status: 0,
        videoState: 0,
      });
      this.setState({
        modalTimer: false
      });
      showToast('Started Successfully')
    } catch (error) {
      console.error('Error updating game state in Firebase:', error);
      this.alertAboutConnectivity();
    }
  }

    async startRace(){
      if(!this.state.connected){
        this.alertAboutConnectivity();
        return
      }
      const currentGame = this.state.currentGame;
      if (!currentGame || !currentGame.timeStamp) return;
      const currentGameRef = ref(this.db, `currentGame`);
      try {
        await update(currentGameRef, {
          status: 1,
          videoState: 1,
          timeStamp: currentGame.timeStamp,
        });
        this.setState({
          modalTimer: true
        });
        showToast('Started Successfully')
      } catch (error) {
        console.error('Error updating game state in Firebase:', error);
        this.alertAboutConnectivity();
      }
    }

    async startGame() {
      if (!this.state.connected) {
        this.alertAboutConnectivity();
        return;
      }

      const currentGame = this.state.currentGame;
      if (currentGame && currentGame.timeStamp) {
        currentGame.active = true;
        currentGame.timeStamp = Date.now();

        // Update the game state in Firebase
        const currentGameRef = ref(this.db, `currentGame`);
        try {
          await update(currentGameRef, {
            active: currentGame.active,
            timeStamp: currentGame.timeStamp,
          });
          this.setState({
            currentGame: currentGame,
          });
          showToast('Game started successfully!');
        } catch (error) {
          console.error('Error updating game state in Firebase:', error);
          this.alertAboutConnectivity();
        }
      }
    }

    async freezePicks() {
      if (!this.state.connected) {
        this.alertAboutConnectivity();
        return;
      }

      const currentGame = this.state.currentGame;
      if (currentGame && currentGame.timeStamp) {
        currentGame.status = currentGame.status === 1 ? 0 : 1;

        // Update the game state in Firebase
        const currentGameRef = ref(this.db, `currentGame`);
        try {
          await update(currentGameRef, {
            status: currentGame.status,
          });
          this.setState({
            currentGame: currentGame,
          });
          showToast(currentGame.status === 1 ? 'Picks Unfrozen!' : 'Picks Frozen!');
        } catch (error) {
          console.error('Error updating game state in Firebase:', error);
          this.alertAboutConnectivity();
        }
      }
    }

    async stopGame() {
      if (!this.state.connected) {
        this.alertAboutConnectivity();
        return;
      }

      const currentGame = this.state.currentGame;
      if (currentGame && currentGame.timeStamp) {
        currentGame.active = false;

        // Update the game state in Firebase
        const currentGameRef = ref(this.db, `currentGame`);
        try {
          await update(currentGameRef, {
            active: currentGame.active,
          });
          this.setState({
            currentGame: currentGame,
          });
          showToast('Game stopped successfully!');
        } catch (error) {
          console.error('Error updating game state in Firebase:', error);
          this.alertAboutConnectivity();
        }
      }
    }

    async pauseRace() {
      if (!this.state.connected) {
        this.alertAboutConnectivity();
        return;
      }

      const currentGame = this.state.currentGame;
      currentGame.status = 2;
      currentGame.videoState = 2;

      // Update the game state in Firebase
      const currentGameRef = ref(this.db, `currentGame`);
      try {
        await update(currentGameRef, {
          status: currentGame.status,
          videoState: currentGame.videoState,
        });
        this.setState({
          currentGame: currentGame,
        });
        showToast('Race paused successfully!');
      } catch (error) {
        console.error('Error updating game state in Firebase:', error);
        this.alertAboutConnectivity();
      }
    }

    async resetRace() {
        if (!this.state.connected) {
          this.alertAboutConnectivity();
          return;
        }

        const currentGame = { ...this.state.currentGame };
        const response = await showConfirmPopup(
            'STOP!',
            'Are you sure you want to do this? This will reset people\'s picks!',
            'Reset'
        );

        if (!response) return;

        this.setState({ loading: true });

        // Reset the game state
        currentGame.status = 0;
        currentGame.videoState = 0;
        currentGame.sentEmail = false;
        currentGame.active = false;

        if (currentGame.pickWinner) {
          currentGame.winner = null;
          for (let i in currentGame.racers) {
            currentGame.racers[i].winner = null;
          }
        }

        for (const index in currentGame.rewards) {
          if (typeof currentGame.rewards[index] === 'object') {
            currentGame.rewards[index].used = 0;
          }
        }

        this.setState({
          sentStatsEmail: false,
          currentGame: currentGame,
          currentUserPicksState: [],
          userAnswerCount: 0
        });

        // Write changes to Firebase
        try {
          const gameRef = ref(this.db, `currentGame`);
          await update(gameRef, currentGame);

          // Remove entries in 'prizeWon' and 'userPicks'
          await remove(ref(this.db, "prizeWon"));
          await remove(ref(this.db, "userPicks"));

          // Remove user game history related to the current game
          const historyRefQuery = query(ref(this.db, `userGameHistory`), orderByChild(currentGame.id), equalTo(currentGame.id));
          const historySnapshot = await get(historyRefQuery);
          const history = historySnapshot.val();

          for (let historyRef in history) {
            let refInstance = history[historyRef];
            if (refInstance && refInstance[currentGame.id]) {
              await set(ref(this.db, `userGameHistory/${historyRef}/${currentGame.id}`), null);
            }
          }

          // Remove redemptions related to the current game
          const redemptionsRef = query(ref(this.db, 'redeemedPrize'), orderByChild("gameId"), equalTo(currentGame.id));
          const redemptionsSnapshot = await get(redemptionsRef);
          const redemptions = redemptionsSnapshot.val();

          for (let redemption in redemptions) {
            let refInstance = redemptions[redemption];
            if (refInstance && refInstance.gameId === currentGame.id && currentGame.id) {
              await set(ref(this.db, `redeemedPrize/${redemption}`), null);
            }
          }

          // Clear emails
          await set(ref(this.db, `emailsSent`), null);
          await set(ref(this.db, `emailsToSend`), null);

          this.setState({ loading: false });
          showToast('Reset Successful');
        } catch (error) {
          console.error("Error during reset:", error);
          this.setState({ loading: false });
          showErrorPopup('Error!', 'Something went wrong while resetting the game. Please try again.');
        }
      }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
        advancedLosingOptions: false,
        showWinning: true
      });
    }

    toggleRacerVideo() {
      this.setState({
        modalRacerVideo: !this.state.modalRacerVideo,
      });
    }

    togglePickRacerModal() {
      this.setState({
        pickWinnerModal: !this.state.pickWinnerModal,
      });
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
      if(buttonClicked === "showStats"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:true,
          showPrizes:false,
          showGames:false
        })
      }else if(buttonClicked === "showPrizes"){
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        this.setState({
          loading: true
        })
        const organizeUserAnswers = await this.sortPlayersForAdmin(null, null, appDatabasePrimaryFunctions, this.state.currentGame, process.env.REACT_APP_IS_MLB_TEAM === "true");
        this.setState({
          showStats:false,
          showPrizes:true,
          showGames:false,
          loading: false,
          currentUserPicksState: organizeUserAnswers
        })
      } else if(buttonClicked === "showGames"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        const futureGameList = await this.getFutureGames();
        this.setState({
          showStats:false,
          showPrizes:false,
          showGames:true,
          futureGamesList: futureGameList
        })
      }
    }

    removeFromToAddArray(index, name){
        let removeArray = this.state[name];
        removeArray.splice(index, 1)
        this.setState({
          [name]: removeArray
        })
    }

    addRacerToLocalArray(){
      const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
      var racersAddedArray = this.state.racersAdded;
      var racerList = this.state.racerList;
      var racerKeys = []
      for(var racerIndex in racersAddedArray){
        racerKeys.push((racersAddedArray[racerIndex].key || racersAddedArray[racerIndex].id))
      }
      if(racerKeys.indexOf(this.state.racer_select) !== -1){
        showInfoPopup(`${isPrediction?"Prediction":"Racer"} Already In Game!`, `This game already includes this ${isPrediction?"prediction":"racer"}. Try adding another ${isPrediction?"prediction":"racer"}.`)
        return;
      }
      var racer = {}
      for(var racerIndex in racerList){
        if((racerList[racerIndex].key  || racerList[racerIndex].id) === this.state.racer_select){
            racer = racerList[racerIndex]
            racer.id = this.state.racer_select
        }
      }
      if(racer.id){
         racer.status = 0
         racer.progress = 0
         racer.progress = 0

         racersAddedArray.push(racer)
         this.setState({
           racersAdded: racersAddedArray
         })
      } else {
        showInfoPopup("Nothing Selected!", "Make sure to select an option before clicking 'Add'")
      }
    }

    setAsWinner(e){
      var racersArray = this.state.racersAdded;
      for(var racer in racersArray){
        if(racer == e){
          racersArray[racer].winner = true
        } else {
          racersArray[racer].winner = false
        }
      }
      this.setState({
        racersAdded: racersArray
      })
    }

    async getFutureGames(numberOfResults = 10) {
      const db = getDatabase();
      const futureGamesRef = ref(db, 'futureGamesList');
      const futureGamesQuery = query(futureGamesRef, orderByChild('activateGame/performAt'), limitToFirst(numberOfResults));

      const snapshot = await get(futureGamesQuery);
      const data = snapshot.exists() ? snapshot.val() : [];

      // Convert the object returned by .val() to an array if needed
      return Object.keys(data).map(key => data[key]);
    }

    async sendDunkinEmail(recipient_objects, winnerRacerIds, gameId, loser_objects){
      const allUsersSnapshot = await get(ref(this.db, 'users'));
      const allUsers = allUsersSnapshot.val() || {};
      const recipients = [];
      const loser_recipients = [];
      for(let recipientObjectIndex in recipient_objects){
        let recipientObject = recipient_objects[recipientObjectIndex]
        let userInUserState = allUsers[recipientObject.id]
        if(userInUserState){
          recipients.push(userInUserState.email)
        }
      }
      for(const loserIndex in loser_objects){
        const loserObject = loser_objects[loserIndex];
        const userInUserState = allUsers[loserObject.id];
        loser_recipients.push(userInUserState.email)
      }
      let winner = "";
      for(const racerIndex in this.state.racerList) {
        const racer = this.state.racerList[racerIndex];
        const racerId = racer.id || racer.key;
        if (winnerRacerIds.indexOf(racerId) !== -1) {
          const winnerRacerName = racer.racerName.toLowerCase().replace(/\s/g, '')
          if (winnerRacerName.indexOf("hot") !== -1) {
            winner = "hot_coffee"
          } else if (winnerRacerName.indexOf("donut") !== -1) {
            winner = "free_donut"
          } else if (winnerRacerName.indexOf("bagel") !== -1) {
            winner = "biggie_bagel"
          } else if (winnerRacerName.indexOf("iced") !== -1) {
            winner = "iced_coffee"
          } else if (winnerRacerName.indexOf("4thinningscore") !== -1) {
            winner = racerId
          } else if (winnerRacerName.indexOf("5thinningscore") !== -1) {
            winner = racerId
          } else if (winnerRacerName.indexOf("6thinningscore") !== -1) {
            winner = racerId
          } else {
            alert("Illegal option selected that is expected! Edit the added options to be one of the allowed options")
            return
          }
        }
      }
      if(winnerRacerIds.length > 1){
        winner = winnerRacerIds;
      }
      const emailVariables = this.state.emailVariables;
      const from_who = emailVariables.sender;
      const subject = emailVariables.subjectLine;
      const loser_subject_line = emailVariables.loserSubjectLine;
      const loser_image_link = emailVariables.loserImageLink;
      const losingEmailImage = emailVariables.losingEmailImage;
      const losingHelpText = emailVariables.losingHelpText;
      const losingDescription = emailVariables.losingDescription;
      const description = emailVariables.winningDescription;
      const helpText = emailVariables.helpText;
      const templateText = emailVariables.templateText;
      const vendorName = emailVariables.vendorName;
      const buttonActionText = emailVariables.buttonActionText || "See My Prize";
      const losingFooterImage = emailVariables.losingFooterImage;
      const losingHeaderImage = emailVariables.losingHeaderImage;
      const winningFooterImage = emailVariables.winningFooterImage;
      const winningHeaderImage = emailVariables.winningHeaderImage;
      const gameObject = {
        losingFooterImage,
        losingHeaderImage,
        winningFooterImage,
        winningHeaderImage,
        from_who,
        subject,
        recipients,
        winner,
        gameId,
        "losers": loser_recipients,
        loser_subject_line,
        helpText,
        loser_image_link,
        "loserImage": losingEmailImage,
        losingHelpText,
        templateText,
        buttonActionText,
        vendorName,
        losingDescription,
        description
      };
      this.setState({loading:true});
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/senddunkinprizes`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/senddunkinprizes`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      this.setState({loading: true});
      let fetchObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify(gameObject)
      }
      let response;
      try{
        response = await fetch(url, fetchObject);
      } catch (e) {
        console.log("ERROR: ", e);
        this.setState({loading: false});
        return;
      }
      const res = await Promise.resolve(response.json());
      this.handleEmailResponse(res, winner);
    }

    async sendStatsEmail() {
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      var currentGame = this.state.currentGame || {};
      var gameName = currentGame.gameName;
      var currentUserPicksState = await this.sortPlayersForAdmin(null, null, appDatabasePrimaryFunctions, currentGame, process.env.REACT_APP_IS_MLB_TEAM === "true") || []
      var emailVariables = this.state.emailVariables;
      const anonymizeData = emailVariables.anonymizeData || false;
      var tenantVariables = this.state.tenantVariables;
      var team_logo = tenantVariables.frontLogoImage;
      var statsEmailReceivers = emailVariables.statsEmailReceivers;
      var recipients = []
      if(statsEmailReceivers){
        var statsEmailReceiversArray = statsEmailReceivers.split(",")
        for(var index in statsEmailReceiversArray){
          var receiver = statsEmailReceiversArray[index]
          recipients.push(receiver.trim())
        }
      }
      if(recipients.length === 0){
        return;
      }
      var prizes_won = 0
      var unique_players = currentUserPicksState.length;
      let identifierWord = "Email";
      if(isMlbApp){
        identifierWord = "OKTAID"
      }
      let reward_report = identifierWord + ",Reward Sent,Code\n"
      if(anonymizeData){
        reward_report = "Reward Sent,Code\n"
      }
      for(var prizeIndex in currentUserPicksState){
        var prize = currentUserPicksState[prizeIndex]
        var email = (prize.email || prize.uid) + ",";
        var reward = prize.rewardSent + ",";
        if(!prize.rewardSent){
          reward = "none,";
        }
        if(prize.rewardSent){
          prizes_won+=1
        }
        var code = prize.code + ",";
        if(!anonymizeData){
          reward_report += email
        }
        reward_report += reward
        reward_report += code
        reward_report += "\n"
      }
      var vm = this;
      prizes_won = prizes_won || 0;
      var sendEmailObject = {
        "recipients": recipients,
        "team_logo": team_logo,
        "prizes_won": prizes_won.toString(),
        "unique_players": unique_players.toString(),
        "reward_report": reward_report,
        "game_name": gameName
      };
      fetch(`${process.env.REACT_APP_EMAIL_SEND_LINK}/stats`, {
        method: 'POST',
        headers: {
           'Content-Type': 'application/json',
           'IDToken': vm.state.id_key
         },
        body: JSON.stringify(sendEmailObject)
      }).then(res => {
        if(!res){
          vm.setState({
            loading:false
          })
          this.alertAboutConnectivity();
          return;
        }
        Promise.resolve(res.json()).then(function(response){
          if(!response){
            this.alertAboutConnectivity();
          } else if(response.message === "Emails sent!"){
            showToast('Successfully Sent!')
            vm.setState({
              modalStats: false
            })
          } else {
            showErrorPopup('Oh No!', response.error)
          }
          vm.setState({
            loading:false
          })
        })
      }).catch(function(err){
        console.log(err)
      })
    }

    saveReward (evt) {
      this.setState({ rewardToAdd: evt.target.value });
    }

    showVideo(videoLink){
      this.toggleRacerVideo();
      this.setState({
        videoLink: videoLink
      })
    }

    async selectWinner(){
      if(!this.state.connected){
        this.alertAboutConnectivity();
        return;
      }

      const markedCheckbox = document.querySelectorAll('input[type="checkbox"]:checked');
      const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
      const otherWinners = [];

      for (let checkbox of markedCheckbox) {
        const racerId = checkbox.id;
        if (racerId) {
          otherWinners.push(racerId);
        }
      }

      const selectedGame = { ...this.state.currentGame }; // Make a copy of currentGame to avoid mutating state directly
      let winnerSelected = false;

      for (let index in selectedGame.racers) {
        const racer = selectedGame.racers[index];
        if (otherWinners.indexOf(racer.id) !== -1) {
          racer.winner = true;
          winnerSelected = true;
          selectedGame.winner = "multiple";
        }
      }

      if (winnerSelected) {
        // Update local state and the database
        this.setState({
          currentGame: selectedGame
        }, async () => {
          // Update the database with the selected winners
          const gameRef = ref(appDatabasePrimaryFunctions, `currentGame`);
          try {
            await update(gameRef, { racers: selectedGame.racers, winner: selectedGame.winner });
            this.togglePickRacerModal();
            this.sortAndPrepareToSendEmail();
          } catch (error) {
            console.error("Error updating game in database:", error);
          }
        });
      } else if (!winnerSelected && isMlbApp) {
        const result = await showConfirmPopup('No Winners Selected', 'Are you sure you want to do this? This will end the game and not send out any prizes', 'Yes I am Sure');
        if (!result.isConfirmed) return;

        this.togglePickRacerModal();
        this.endGameAfterEmailsSent();
      }
    }

    addRewardToLocalArray(){
      const rewardAmount = this.state.rewardAmount;
      const rewardId = this.state.rewardToAdd;
      const codes = this.state.codes;
      let codesArray = this.state.codesArray;
      let pinsArray = this.state.pinsArray;
      const qrCodes = this.state.qrCodes;
      codesArray = detectHowManyCodesEntered(codesArray);
      pinsArray = detectHowManyCodesEntered(pinsArray);
      if(codesArray.length === 0 && codes){
        showInfoPopup('Hold on!', 'Must enter codes or uncheck codes box!');
        return;
      } else if (codes){
        if(codesArray.length !== parseInt(rewardAmount,10)){
          showInfoPopup('Codes not equal!', "# of codes must equal number of prizes created. In this case: " +  codesArray.length + " does not equal " + rewardAmount);
          return;
        } else if(pinsArray.length !== 0 && pinsArray.length !== parseInt(rewardAmount,10)){
          showInfoPopup('Pins not equal!', "# of pins must equal number of prizes created. In this case: " +  pinsArray.length + " does not equal " + rewardAmount);
          return;
        }
      }

      if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
        showInfoPopup('Missing field!', "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward");
        return;
      }

      for(var ticket in this.state.ticketList){
        if((this.state.ticketList[ticket].key || this.state.ticketList[ticket].id) === rewardId){
          var reward = this.state.ticketList[ticket]
          var rewardToAddObject = {};
          rewardToAddObject.reward = reward;
          rewardToAddObject.amount = rewardAmount;
          rewardToAddObject.codes = codes;
          if(codes){
            rewardToAddObject.codesArray = codesArray;
            rewardToAddObject.pinsArray = pinsArray;
            rewardToAddObject.qrCodes = qrCodes;
          } else {
            rewardToAddObject.codesArray = [];
            rewardToAddObject.pinsArray = [];
            rewardToAddObject.qrCodes = false;
          }
        }
      }

      let typeOfArray = "rewardsAdded"
      let array = this.state.rewardsAdded;
      if(!this.state.showWinning){
        array = this.state.losingRewardsAdded;
        typeOfArray = "losingRewardsAdded"
      }

      const rewardsAddedIds = []
      for(let prize in array){
        rewardsAddedIds.push(array[prize].reward.key || array[prize].reward.id)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          [typeOfArray]: array,
          qrCodes: false,
          codes: false
        })
      } else {
        showInfoPopup('Prize already added!', "You have already added this prize");
      }
    }

    switchWinning(showBoolean) {
      if (showBoolean) {
        document.getElementById('showWinning').classList.add('active');
        document.getElementById('showLosing').classList.remove('active');
      } else {
        document.getElementById('showWinning').classList.remove('active');
        document.getElementById('showLosing').classList.add('active');
      }
      this.setState({
        showWinning: showBoolean
      })
    }

    async setRewardRedeemed(reward, index){
      const response = await showConfirmPopup(
          'Hold on!',
          'This prize redemption process is handled automatically by Sqwad, are you sure you want to toggle this?',
      );

      if(!response.isConfirmed) return;
      let uid = reward.uid || "";
      const currentGame = this.state.currentGame || {};
      const gameId = currentGame.id || "";
      const prizeWonId = reward.prizeWonId || reward.sendPrizeId || "";
      if(!uid || !gameId || !prizeWonId) return;
      this.setState({loading: true});
      let valueToSet = false;
      const redeemedPrizeRef = ref(this.db, `redeemedPrize/${prizeWonId}`);
      const redeemedPrizeSnapshot = await get(redeemedPrizeRef);
      const redeemedPrize = redeemedPrizeSnapshot.val();

      if (redeemedPrize && redeemedPrize.redeemed) {
        await update(redeemedPrizeRef, { redeemed: false, timeRedeemed: null });
      } else {
        valueToSet = true;
        await update(redeemedPrizeRef, { redeemed: true, timeRedeemed: Date.now() });
      }
      const currentUserPicksState = this.state.currentUserPicksState;
      currentUserPicksState[index].isRedeemed = valueToSet;
      this.setState({
        currentUserPicksState: currentUserPicksState,
        loading: false
      })
    }

  async sortPlayersForAdmin (numberOfResults = 0, searchData = null, primaryDb = null, currentGame = {}, isMlbApp)  {
    const promiseArray = await Promise.all([
      this.getSnapshotFromEndpoint('emailsToSend', primaryDb),
      this.getSnapshotFromEndpoint('emailsSent', primaryDb),
      this.getSnapshotFromEndpoint('redeemedPrize', primaryDb, null, null, 'gameId', currentGame.id)
    ]);

    const emailsToSend = promiseArray[0].val() || {};
    const emailsSent = promiseArray[1].val() || {};
    let prizeRedemptions = {};
    promiseArray[2].forEach(function (data) {
      prizeRedemptions[data.val().uid] = data.val();
    });

    let userAnswersQuery = query(ref(primaryDb, 'userPicks'), orderByChild('timeStamp'));
    if (numberOfResults) {
      userAnswersQuery = query(userAnswersQuery, limitToFirst(numberOfResults));
    }

    const userAnswersSnapshot = await get(userAnswersQuery);
    const tempUserAnswerArray = [];
    userAnswersSnapshot.forEach(function (userAnswerRef) {
      tempUserAnswerArray.push(userAnswerRef.val());
    });

    const usersPicksAttempted = [];
    for (const i in tempUserAnswerArray) {
      const user = {};
      const pick = tempUserAnswerArray[i];
      let successConverting = false;
      let notEmails = false;
      const rewardSent = (currentGame && currentGame.sentEmail) || false;
      let code = "";
      let rewardName = "";
      let sentEmailObjects = emailsSent || emailsToSend;
      if (rewardSent && sentEmailObjects && sentEmailObjects[pick.uid]) {
        code = sentEmailObjects[pick.uid].barcode || sentEmailObjects[pick.uid].code || "";
        rewardName = sentEmailObjects[pick.uid].name || "Yes";
      }

      let timeStamp = pick.timeStamp;
      let correctPick = false;
      const winningRacerIds = [];
      let pickName = "No";

      for (let racerIndex in currentGame.racers) {
        const racer = currentGame.racers[racerIndex];
        if (racer.id === pick.pickid) {
          pickName = racer.racerName;
        }
        if (racer.winner) {
          winningRacerIds.push(racer.id);
        }
      }

      if (winningRacerIds.includes(pick.pickid)) {
        correctPick = true;
      }

      let userEmail = pick.uid;
      if (!notEmails && !isMlbApp) {
        if (!successConverting) {
          try {
            userEmail = atob(userEmail);
            successConverting = true;
          } catch (e) {
            notEmails = true;
          }
        } else {
          userEmail = atob(userEmail);
        }
      }

      user['timeStamp'] = timeStamp;
      user['pick'] = pickName;
      user['pickCorrect'] = correctPick;
      user['email'] = userEmail;
      user['rewardSent'] = rewardName;
      user['uid'] = pick.uid;
      user['code'] = code;
      if (isMlbApp) {
        user['opens'] = pick.opens || 0;
      }

      const redeemedPrize = prizeRedemptions[pick.uid] || {};
      if (redeemedPrize.sendPrizeId) {
        user['isRedeemed'] = redeemedPrize.redeemed;
        user['prizeWonId'] = redeemedPrize.sendPrizeId;
      }

      usersPicksAttempted.push(user);
    }

    const cmp = (a, b) => (a > b) - (a < b);
    usersPicksAttempted.sort(function (a, b) {
      return cmp(b.pickCorrect, a.pickCorrect) || cmp(a.timeStamp, b.timeStamp);
    });

    return usersPicksAttempted;
  };

  async getSnapshotFromEndpoint(endpoint, primaryDb, numberOfResults = null, searchData = null, orderBy = null, equalToValue = null) {
    let baseQuery = ref(primaryDb, endpoint);

    if (orderBy) {
      baseQuery = query(baseQuery, orderByChild(orderBy));
      if (equalToValue) {
        baseQuery = query(baseQuery, equalTo(equalToValue));
      }
    }

    if (numberOfResults) {
      baseQuery = query(baseQuery, limitToFirst(numberOfResults));
    }

    return await get(baseQuery);
  };

    // async sendTestEmail(){
    //     const emailsAlreadyReceived = [];
    //     const codesAlreadySent = [];
    //     const date = new Date();
    //     const vm = this;
    //     await appDatabasePrimaryFunctions.ref('emailsSent').once('value', function (snapshot){
    //         snapshot.forEach(function(data){
    //             emailsAlreadyReceived.push(data.key)
    //             const newData = data.val();
    //             codesAlreadySent.push(newData.code)
    //         })
    //     })
    //     console.log(emailsAlreadyReceived.length)
    //     appDatabasePrimaryFunctions.ref('userPicks').once('value', async function (snapshot) {
    //         const correctAnswers = [];
    //         snapshot.forEach(function (data) {
    //             const pick = data.val();
    //             if (pick.pickid === "-MwYX5v3mB_vARmxytfW" && emailsAlreadyReceived.indexOf(pick.uid) === -1) {
    //                 correctAnswers.push(pick.uid)
    //             }
    //         });
    //        const rewardsAvailable = await appDatabasePrimaryFunctions.ref('currentGame/rewards/-MzRBVyIC8pjsYDX4suW/codesArray').once('value').then(function (snapshotRewards) {
    //             return snapshotRewards.val();
    //        })
    //         // console.log(rewardsAvailable)
    //         let expiresAt = new Date(date.setMonth(date.getMonth() + 1)).toISOString();
    //         let tempCodesAvailable = [];
    //         for (const correctAnswerIndex in correctAnswers) {
    //             let correctAnswer = correctAnswers[correctAnswerIndex];
    //             for(const codeAvailableIndex in rewardsAvailable){
    //                 const codeAvailable = rewardsAvailable[codeAvailableIndex];
    //                 if(codesAlreadySent.indexOf(codeAvailable) === -1 && tempCodesAvailable.indexOf(codeAvailable) === -1){
    //                     tempCodesAvailable.push(codeAvailable);
    //                     let imageUrl = "ballpark://webview?url=" + encodeURIComponent("https://marinersrace.web.app" + "/prizeviewer?code="+codeAvailable+"&qrcode=false&pin=&image="+'https://react-game-center-uploads.s3.amazonaws.com/compressed_7cb9134f85d54ff5a66936faed5ca8f73f87293c105442b2a61a4f5bb61f6f15.png');
    //                     await vm.sendMlbMessage(correctAnswer, imageUrl, "Hydro Boat Race Results!", expiresAt, "8501c9f022fd9ca5ec68#{$}", "Mariners. Congratulations!  You’ve scored a free buffet at Snoqualmie Casino’s Falls Buffet! Go to the Crescent Club at Snoqualmie Casino and present this message. 72 hours to redeem your prize Must be 21 years or older to redeem and can win one Snoqualmie Casino “winner” prize every 30 days.", "Click HERE to see my prize", "Snoqualmie Casino", "680", "MascotRace/1.0")
    //                     console.log(correctAnswer, codeAvailable, imageUrl, "Hydro Boat Race Results!", expiresAt, "8501c9f022fd9ca5ec68#{$}", "Mariners. Congratulations!  You’ve scored a free buffet at Snoqualmie Casino’s Falls Buffet! Go to the Crescent Club at Snoqualmie Casino and present this message. 72 hours to redeem your prize Must be 21 years or older to redeem and can win one Snoqualmie Casino “winner” prize every 30 days.", "Click HERE to see my prize", "Snoqualmie Casino", "680", "MascotRace/1.0");
    //                     appDatabasePrimaryFunctions.ref(`emailsSent/${correctAnswer}`).set({
    //                         code: codeAvailable,
    //                         rewardName: "Free Buffet"
    //                     })
    //                     break;
    //                 }
    //             }
    //             // if(reward.emailImage){
    //             //     const siteUrl = getUrlFromDbUrl();
    //             //     reward.emailImage = "ballpark://webview?url=" + encodeURIComponent(siteUrl + "/prizeviewer?code="+code+"&qrcode="+convertCodeToQrCode+"&pin="+pin+"&image="+reward.emailImage);
    //             // }
    //             // sendMlbMessage(correctAnswer, )
    //             // console.log(correctAnswer)
    //         }
    //
    //     })
    //   // let winner = "hot_coffee"
    //   // const emailVariables = this.state.emailVariables;
    //   // const from_who = emailVariables.sender;
    //   // const subject = emailVariables.subjectLine;
    //   // const helpText = emailVariables.helpText;
    //   // // const losers = ['']
    //   // const gameObject = {"from_who": from_who, "subject": subject, "recipients": [], "winner": winner, "helpText": helpText, losers: [], };
    //   // // console.log(gameObject)
    //   // // this.setState({
    //   // //   loading:true
    //   // // });
    //   // fetch(`${process.env.REACT_APP_EMAIL_SEND_LINK}/rewards`, {
    //   //   method: 'POST',
    //   //   headers: {
    //   //     'Content-Type': 'application/json',
    //   //     'idtoken': this.state.id_key
    //   //   },
    //   //   body: JSON.stringify(gameObject)
    //   // }).then(response => {
    //   //   console.log(response)
    //   // })
    // }

    render() {
      let selectedGame = this.state.currentGame || {};
      const hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions || false;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      const isDunkin = process.env.REACT_APP_GAME_VERSION === "dunkin";
      const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
      const isSportsRadarWorking = process.env.REACT_APP_SPORTS_RADAR_TEAM_ID;
      const sportsRadarSeasonSchedule = this.state.sportsRadarSeasonSchedule || [];
      const futureGamesList = this.state.futureGamesList || [];
      const userAnswerCount = this.state.userAnswerCount || 0;
      const tenantVariables = this.state.tenantVariables || {};
      let racerList = [];
      let status;
      let sentEmail;
      let gameActive;
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let showTime = false;
      let pickWinner = false;
      const rewardsList = [];
      const losingRewardsList = [];
      let rewardsAdded = this.state.rewardsAdded || [];
      if(!this.state.showWinning){
        rewardsAdded = this.state.losingRewardsAdded || [];
      }
      let currentUserPicksState = this.state.currentUserPicksState || [];
      let videoLength;
      let emailsSentButtonText = "Emails Sent!"
      let useStatFeed;
      if(selectedGame && selectedGame.gameName){
        for(const i in selectedGame.rewards){
          const reward = selectedGame.rewards[i];
          if(typeof reward === "object"){
            rewardsList.push(reward);
          }
        }
        for(const i in selectedGame.losingRewards){
          const reward = selectedGame.losingRewards[i];
          if(typeof reward === "object"){
            losingRewardsList.push(reward);
          }
        }
        gameActive = selectedGame.active;
        sentEmail = selectedGame.sentEmail;
        status = selectedGame.status;
        pickWinner = selectedGame.pickWinner;
        racerList = selectedGame.racers || [];
        useStatFeed = selectedGame.useStatFeed || false;
        for(const racerIndex in racerList){
          const racer = racerList[racerIndex];
          if(racer.winner){
            videoLength = racer.videoLength
          }
        }
        if((rewardsList.length === 0 || tenantVariables.doNotCollectEmail || isMlbApp) && videoLength){
          emailsSentButtonText = "Video Played"
        } else if(rewardsList.length === 0 || tenantVariables.doNotCollectEmail || isMlbApp){
          emailsSentButtonText = "Game Ended"
        }
        if(selectedGame.activateGame){
          startTimeFirstPart = convertTimeStampToHumanReadable(selectedGame.activateGame.performAt);
          showTime = true;
        }
      } else {
        selectedGame = null;
        gameActive = false;
        sentEmail = false;
        status = 0;
      }
      const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                {/*<button onClick={() => this.sendTestEmail()} className="btn btn-primary btn-lg start-game-button" style={{float:'left', height:'52px'}}>*/}
                {/*  SEND TEST EMAIL*/}
                {/*</button>*/}
                <div className="col-md-3">
                  <button onClick={() => useStatFeed ? this.togglePickRacerModal() : !videoLength ? pickWinner ? this.togglePickRacerModal() : this.sortAndPrepareToSendEmail() : this.startRace()} className="btn btn-primary btn-lg start-game-button" style={{display: sentEmail ? 'none' : '', float:'left', height:'52px'}}>
                    {useStatFeed ? "Manually Select" :  !videoLength ? (tenantVariables.doNotCollectEmail || isMlbApp) ? "End Game" : pickWinner ? "Pick Winner" : "Send Email" : "Start Video" }
                  </button>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: sentEmail ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>{emailsSentButtonText}</p>
                  </div>
                </div>
                <center className="col-md-1" style={{display: gameActive ? '' : 'none', color:'black'}}>
                  Advanced<input id="advanceSettings" name="advanceSettings" type="checkbox" checked={this.state.advanceSettings} onChange={this.handleChange} />
                </center>
                <div className="col-md-3">
                  <button onClick={() => this.freezePicks()} className="btn btn-primary btn-lg start-game-button" style={{display: !gameActive || status === 2 || !this.state.advanceSettings  ? 'none' : '', float:'left', height:'52px', marginBottom: 10}}>{status !== 1 ? "Freeze" : "Unfreeze"}</button>
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive && this.state.advanceSettings ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                <div className="col-md-3">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetRace()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft:10, marginTop: 10, marginBottom:10, borderRadius: 5, display: !showTime && "none"}}>
                      <u style={{color:"#353839"}}>Starts</u>
                      <br/>
                      <span style={{fontSize:18}}>{startTimeFirstPart || "No Start Time"}<br/>{startTimeSecondPart}</span>
                  </div>
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft: 10, marginTop:10, marginBottom:10, borderRadius: 5}}>
                    <div className="row">
                      <div className="col-md-12" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>Current Game Players</span>
                        <footer className="value-text">{userAnswerCount}</footer>
                      </div>
                    </div>
                  </div>
                  {hasRewardWithAutoRedemptions &&
                      <div className="col-md-2" style={{
                        color: "black",
                        border: "solid 1px #d3d3d3",
                        marginLeft: 10,
                        marginTop: 10,
                        marginBottom: 10,
                        borderRadius: 5
                      }}>
                        <div className="row">
                          <div className="col-md-12" style={{textAlign: 'center'}}>
                            <span style={{fontSize: 12}}>Redemptions</span>
                            <footer className="value-text">{this.state.redemptions || 0}</footer>
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                    <div className="export-button-styles btn btn-primary btn-lg download-button" style={{marginBottom:10}} onClick={()=>this.downloadUsers()}>
                        <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>{tenantVariables.doNotCollectEmail || isMlbApp ? "UID" : "Email"}</th>
                        <th>Pick</th>
                        <th>Time Stamp</th>
                        <th>Place</th>
                        <th>Reward</th>
                        <th>Code</th>
                        {(isMlbApp) &&
                          <th>Opens</th>
                        }
                        {hasRewardWithAutoRedemptions &&
                            <th>Redeemed</th>
                        }
                      </tr>
                      {
                        currentUserPicksState.map(function(item,i){
                            const timeStamp = convertTimeStampToHumanReadable(item.timeStamp)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{tenantVariables.doNotCollectEmail || isMlbApp ? item.uid : item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.pick}</td>
                              <td style={{fontFamily:'Open Sans' }}>{timeStamp}</td>
                              <td style={{fontFamily:'Open Sans' }}>{i + 1}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                              {(isMlbApp) &&
                                <td style={{fontFamily:'Open Sans' }}>{item.opens || 0}</td>
                              }
                              {hasRewardWithAutoRedemptions &&
                                  <td style={{fontFamily: 'Open Sans'}}>
                                    <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly/>
                                    <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                  </td>
                              }
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Rewards</h4>
                        {rewardsList.length > 0 ?
                            rewardsList.map(function(item, index){
                              return (
                                  <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                    <div className="col-md-6">
                                      {item.rewardName}
                                    </div>
                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                      Amount: {item.amount}
                                    </div>
                                  </div>
                              )
                            })
                            :
                            <div style={{textAlign:'left', color:'black'}}>
                              <p>{isDunkin ? "Winners will receive a Dunkin Donuts coupon." : "No Rewards Added"}</p>
                            </div>
                        }
                        {losingRewardsList && losingRewardsList.length > 0 &&
                            <>
                              <h4 style={{color:'black', marginTop: 10}}>Losing Rewards</h4>
                              {
                                losingRewardsList.map(function(item, index){
                                  return (
                                      <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                        <div className="col-md-6">
                                          {item.rewardName}
                                        </div>
                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                          Amount: {item.amount}
                                        </div>
                                      </div>
                                  )
                                })
                              }
                            </>
                        }
                      </div>
                      <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Details</h4>
                        {selectedGame && selectedGame.gameName &&
                          <div style={{textAlign:'left', color:'black'}}>
                            Game Name: {selectedGame.gameName}
                            {isDunkin &&
                              <span>
                                <br/>
                                Losing Prize: {selectedGame.sendLosingEmails ? "Yes" : "No"}
                              </span>
                            }
                            {selectedGame.racers &&
                              selectedGame.racers.map(function(item, index){
                                return (
                                    <div key={index} style={{marginTop: 5}}>
                                      <p>Choice: <img src={item.gifSelectionImage} width="50px" height="auto" alt=""/>{item.racerName} {item.winner && "WINNER!"} {selectedGame.loserSpecialSelect === item.id && !item.winner && "Selected Loser"}</p>
                                      {item.winner && item.racerWinningVideoLink && !selectedGame.pickWinner &&
                                        <button className="btn btn-primary" onClick={()=>this.showVideo(item.racerWinningVideoLink)}>Winning Video Link</button>
                                      }
                                    </div>
                                )
                              }, this)
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Winning {isPrediction?"Prediction":"Racer"}</th>
                        <th>{isPrediction?"Prediction":"Racers"}</th>
                        <th>Make Current Game</th>
                        <th>Delete Game</th>
                      </tr>
                      {
                        futureGamesList.map(function(item,i){
                          var winningRacer = null;
                          for(var winnerIndex in item.racers){
                            var racer = item.racers[winnerIndex];
                            if(racer.winner){
                              winningRacer = racer.racerName;
                            }
                          }
                          var dateTime = convertTimeStampToHumanReadable(item.activateGame.performAt)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                              <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                              <td style={{fontFamily:'Open Sans' }}>{winningRacer}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.racers.length}</td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> Add Choice</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')} style={{display: isDunkin ? "none" : ""}}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                          <div className="col-sm-9">
                                              <input id="gameName" name="gameName" className="form-control" type="text" value={this.state.gameName} onChange={this.handleChange} placeholder="Example: 10/13 Game Dallas" />
                                          </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="name3"> Select Choice</label>
                                          <div className="col-md-9">
                                            <select className="form-control" name="racer_select" id="racer_select" value={this.state.racer_select} onChange={this.handleChange}>
                                              <option/>
                                              {
                                                this.state.racerList.map(function(item,index){
                                                  return(
                                                    <option value={item.key || item.id} key={index}>{item.racerName}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>
                                      {isDunkin &&
                                        <div className="form-check">
                                          <input id="sendLosingEmails" className="form-check-input" name="sendLosingEmails" type="checkbox" checked={this.state.sendLosingEmails} onChange={this.handleChange} />
                                          <label className="form-check-label" htmlFor="sendLosingEmails">Send Losing Email</label>
                                        </div>
                                      }
                                    </div>
                                </div>
                            </form>
                          {(!isDunkin || isMlbApp) &&
                            <div className="form-check">
                              <input id="pickWinner" className="form-check-input" name="pickWinner" type="checkbox" checked={this.state.pickWinner} onChange={this.handleChange}/>
                              <label className="form-check-label" htmlFor="pickWinner">Pick Winner After</label>
                            </div>
                          }
                          {(process.env.REACT_APP_STAT_FEED && process.env.REACT_APP_TIME_ZONE && process.env.REACT_APP_STAT_FEED_TEAM_ID) &&
                              <div className="form-check">
                                <input id="useStatFeed" className="form-check-input" name="useStatFeed" type="checkbox" checked={this.state.useStatFeed} onChange={this.handleChange}/>
                                <label className="form-check-label" htmlFor="useStatFeed">Use Stat Feed</label>
                              </div>
                          }
                          {this.state.useStatFeed &&
                              <select name="statFeedOption" onChange={this.handleChange}>
                                <option value="">-- Select an option --</option>
                                <option value="first-goal-scored">First Goal Scored</option>
                              </select>
                          }
                          <div className="form-group mb-3">
                                <ol>
                                  {
                                    this.state.racersAdded.map(function(item,index){
                                      return(
                                        <li key={index} style={{margin:10}}> {item.racerName}
                                          <span style={{marginLeft:"40px"}}>
                                            {!this.state.pickWinner && !this.state.useStatFeed &&
                                              <>
                                                <button className="btn btn-success btn-admin" onClick={()=>this.setAsWinner(index)} style={{display: item.winner ? 'none' : ''}}>SET AS WINNER</button>
                                                <span className="btn btn-success btn-admin" style={{display: item.winner ? '' : 'none'}}>WINNER</span>
                                              </>
                                            }
                                            <button className="btn btn-danger btn-admin" style={{marginLeft:"40px"}} onClick={()=>this.removeFromToAddArray(index, "racersAdded")}><span className="fa fa-trash"/></button>
                                          </span>
                                        </li>
                                      )
                                    }, this)
                                  }
                                </ol>
                            </div>
                            <div className="form-group row mb-3" align="center">
                              <div className="col-md-12">
                                <button className="btn btn-primary btn-admin" onClick={()=>this.addRacerToLocalArray()}>Add</button>
                              </div>
                            </div>
                        </div>

                         <div className="tab-pane" id="second">
                            <div className="form-check">
                              <input onChange={this.handleChange} name="noPrizes" value={this.state.noPrizes} checked={this.state.noPrizes} type="checkbox" className="form-check-input" id="noPrizes"/>
                              <label className="form-check-label" htmlFor="exampleCheck1">No Prizes</label>
                            </div>
                           {!this.state.noPrizes &&
                               <div className="form-horizontal">
                                 <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                   <li className="nav-item" onClick={()=> this.switchWinning(true)}>
                                     <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showWinning">
                                       <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                       <span className="d-none d-lg-block">Winning</span>
                                     </a>
                                   </li>
                                   <li className="nav-item" onClick={()=> this.switchWinning(false)}>
                                     <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showLosing">
                                       <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                       <span className="d-none d-lg-block">Losing</span>
                                     </a>
                                   </li>
                                 </ul>
                                 <div className="row">
                                   <div className="col-12">
                                     <div className="form-group row mb-3">
                                       <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                       <div className="col-md-9">
                                         <select className="form-control" onChange={this.saveReward}>
                                           <option/>
                                           {
                                             this.state.ticketList.map(function(item,index){
                                               return(
                                                   <option value={item.key || item.id} key={index}>{item.rewardName}</option>
                                               )
                                             })
                                           }
                                         </select>
                                       </div>
                                     </div>
                                     <div className="form-group row mb-3">
                                       <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                       <div className="col-md-9">
                                         <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                       </div>
                                     </div>

                                     <div className="form-check">
                                       <input onChange={this.handleChange} name="codes" value={this.state.codes} checked={this.state.codes} type="checkbox" className="form-check-input" id="codes"/>
                                       <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>
                                     </div>

                                     {this.state.codes && !tenantVariables.doNotCollectEmail &&
                                         <div className="form-check">
                                           <input onChange={this.handleChange} name="qrCodes" value={this.state.qrCodes} checked={this.state.qrCodes} type="checkbox" className="form-check-input" id="qrCodes"/>
                                           <label className="form-check-label" htmlFor="qrCodes">QR Codes?</label>
                                         </div>
                                     }

                                     <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                       <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                       <div className="col-md-9">
                                         <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                       </div>
                                     </div>

                                     <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                       <label htmlFor="pinsArray" className="col-md-3 col-form-label">Enter Pins</label>
                                       <div className="col-md-9">
                                         <textarea value={this.state.pinsArray} className="form-control" name="pinsArray" onChange={this.handleChange} placeholder="pin1,pin2,pin3"/>
                                       </div>
                                     </div>

                                     <div className="form-group row mb-3" align="center">
                                       <div className="col-md-12">
                                         <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                       </div>
                                     </div>

                                     {rewardsAdded && rewardsAdded.length > 1 &&
                                         <div className="form-check" >
                                           <input value={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} className="form-check-input" id={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} name={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} type="checkbox" checked={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} onChange={this.handleChange} />
                                           <label className="form-check-label" htmlFor={this.state.showWinning ? "randomOrder" : "losingRandomOrder"}>Give Out In Random Order</label>
                                         </div>
                                     }

                                     <div className="form-group mb-3">
                                       <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                         {
                                           rewardsAdded.map(function(item,index){
                                             let codesText = "No"
                                             if(item.codes){
                                               codesText = "Yes"
                                             }
                                             return(
                                                 <div key={index} className="form-group">
                                                   <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.state.showWinning ? vm.removeFromToAddArray(index, "rewardsAdded") : vm.removeFromToAddArray(index, "losingRewardsAdded")}/></li>
                                                 </div>
                                             )
                                           })
                                         }
                                       </ol>
                                     </div>
                                     {!this.state.showWinning &&
                                         <div className="form-check">
                                           <input onChange={this.handleChange} name="advancedLosingOptions" value={this.state.advancedLosingOptions} checked={this.state.advancedLosingOptions} type="checkbox" className="form-check-input" id="advancedLosingOptions"/>
                                           <label className="form-check-label" htmlFor="advancedLosingOptions">Advanced</label>
                                         </div>
                                     }
                                     {!this.state.showWinning && this.state.advancedLosingOptions &&
                                         <>
                                           <div className="form-group row mb-3">
                                             <label className="col-md-3 col-form-label" htmlFor="name3"> Select Losing Participant Who Should Get Losing Prize</label>
                                             <div className="col-md-9">
                                               <select className="form-control" name="loserSpecialSelect" id="loserSpecialSelect" value={this.state.loserSpecialSelect} onChange={this.handleChange}>
                                                 <option/>
                                                 {
                                                   this.state.racersAdded.map(function(item,index){
                                                     return(
                                                         <option value={item.key || item.id} key={index}>{item.racerName}</option>
                                                     )
                                                   })
                                                 }
                                               </select>
                                             </div>
                                           </div>
                                         </>
                                     }
                                     <div className="form-check">
                                       <input onChange={this.handleChange} name="allRewardsAdvancedSettings" value={this.state.allRewardsAdvancedSettings} checked={this.state.allRewardsAdvancedSettings} type="checkbox" className="form-check-input" id="allRewardsAdvancedSettings"/>
                                       <label className="form-check-label" htmlFor="allRewardsAdvancedSettings">All Rewards Advanced Settings</label>
                                     </div>
                                     {this.state.allRewardsAdvancedSettings &&
                                         <div className="form-check">
                                           <input onChange={this.handleChange} name="dontShufflePicks" value={this.state.dontShufflePicks} checked={this.state.dontShufflePicks} type="checkbox" className="form-check-input" id="dontShufflePicks"/>
                                           <label className="form-check-label" htmlFor="dontShufflePicks">Order Fan Picks By Time Stamp</label>
                                         </div>
                                     }
                                     <div style={{height: 20,width:1}}/>
                                   </div>
                                 </div>
                               </div>
                           }
                        </div>

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="form-check">
                                  <input value={this.state.schedule_game} className="form-check-input" id="schedule_game" name="schedule_game" type="checkbox" checked={this.state.schedule_game} onChange={this.handleChange} />
                                  <label className="form-check-label" htmlFor="schedule_game">Schedule Game</label>
                                </div>
                                {this.state.schedule_game && isMlbApp && isSportsRadarWorking && sportsRadarSeasonSchedule && sportsRadarSeasonSchedule.length > 0 &&
                                  <>
                                    <div className="row" style={{justifyContent: "center"}}>
                                      MLB Regular Season Schedule
                                    </div>
                                    <div className="row" style={{justifyContent: "center", marginBottom: 20}}>
                                      <select name="mlbGameScheduleId" id="mlbGameScheduleId" value={this.state.mlbGameScheduleId} onChange={this.handleChange}>
                                        <option/>
                                        {sportsRadarSeasonSchedule && sportsRadarSeasonSchedule.length > 0 &&
                                            sportsRadarSeasonSchedule.map(function (item,index) {
                                              let teamName;
                                              let gameDate;
                                              if(item.away_team === process.env.REACT_APP_SPORTS_RADAR_TEAM_ID){
                                                teamName = item.home.name;
                                              } else if(item.home_team === process.env.REACT_APP_SPORTS_RADAR_TEAM_ID){
                                                teamName = item.away.name;
                                              }
                                              gameDate = item.scheduled.split("+")[0];
                                              gameDate = new Date(gameDate + "Z");
                                              const dateTime = convertTimeStampToHumanReadable(gameDate.getTime());
                                              gameDate = cleanDateForSelectDropdown(dateTime);
                                              return(
                                                  <option value={item.id}>{`vs. ${teamName} ${gameDate}`}</option>
                                              )
                                            })
                                        }
                                      </select>
                                    </div>
                                  </>
                                }
                                <div className="form-group row mb-4 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                    <label className="col-md-4 col-form-label" htmlFor="schedule_game_time"> Game Start Time</label>
                                    <div className="col-md-8">
                                      <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time} onChange={date => this.setState({schedule_game_time: date})}/>
                                    </div>
                                </div>
                                {this.state.schedule_game && isMlbApp && isSportsRadarWorking && this.state.mlbGameScheduleId &&
                                    <>
                                      <div className="row" style={{justifyContent: "center"}}>
                                        OR Inning
                                      </div>
                                      <div className="row" style={{justifyContent: "center"}}>
                                        <select name="startInningScheduleTime" id="startInningScheduleTime" value={this.state.startInningScheduleTime} onChange={this.handleChange}>
                                          <option value="1" label="1st"/>
                                          <option value="2" label="2nd"/>
                                          <option value="3" label="3rd"/>
                                          <option value="4" label="4th"/>
                                          <option value="5" label="5th"/>
                                          <option value="6" label="6th"/>
                                          <option value="7" label="7th"/>
                                          <option value="8" label="8th"/>
                                          <option value="9" label="9th"/>
                                        </select>
                                        <select name="startInningSchedulePart" id="startInningSchedulePart" value={this.state.startInningSchedulePart} onChange={this.handleChange}>
                                          <option value="Top" label="Top"/>
                                          <option value="Mid" label="Mid"/>
                                          <option value="Bot" label="Bot"/>
                                        </select>
                                      </div>
                                    </>
                                }
                                {this.state.schedule_game && isMlbApp && isSportsRadarWorking && this.state.mlbGameScheduleId &&
                                    <>
                                      <div className="form-group row mb-4 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                        <label className="col-md-4 col-form-label" htmlFor="schedule_game_time_end"> Game End Time (Beware does not currently send prizes)</label>
                                        <div className="col-md-8">
                                          <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time_end} onChange={date => this.setState({schedule_game_time_end: date})}/>
                                        </div>
                                      </div>
                                      <div className="row" style={{justifyContent: "center"}}>
                                        OR Inning
                                      </div>
                                      <div className="row" style={{justifyContent: "center"}}>
                                        <select name="endInningScheduleTime" id="endInningScheduleTime" value={this.state.endInningScheduleTime} onChange={this.handleChange}>
                                          <option value="1" label="1st"/>
                                          <option value="2" label="2nd"/>
                                          <option value="3" label="3rd"/>
                                          <option value="4" label="4th"/>
                                          <option value="5" label="5th"/>
                                          <option value="6" label="6th"/>
                                          <option value="7" label="7th"/>
                                          <option value="8" label="8th"/>
                                          <option value="9" label="9th"/>
                                        </select>
                                        <select name="endInningSchedulePart" id="endInningSchedulePart" value={this.state.endInningSchedulePart} onChange={this.handleChange}>
                                          <option value="Top" label="Top"/>
                                          <option value="Mid" label="Mid"/>
                                          <option value="Bot" label="Bot"/>
                                        </select>
                                      </div>
                                    </>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>
                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation !== 'first' && !isDunkin ? '' : 'none' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation !== 'third' && !isDunkin ? '' : 'none' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal isOpen={this.state.pickWinnerModal} toggle={this.togglePickRacerModal} style={{width: '90%'}} id="pickWinnerModal">
            <ModalHeader toggle={this.togglePickRacerModal}>
              <h1>Pick Winner</h1>
            </ModalHeader>
            <ModalBody>
              <div className="container-out">
                <div className="row" style={{margin:10}}>
                  <div className="col-md-12" style={{textAlign:"center"}}>
                    {selectedGame &&
                      selectedGame.racers.map(function(item,index){
                        return(
                            <div className="form-check" key={index}>
                              <input className="form-check-input" id={item.id} name={item.id} type="checkbox" onChange={this.handleChange} />
                              <label className="form-check-label" htmlFor={item.id}>{item.racerName}</label>
                            </div>
                        )
                      }, this)
                    }
                  </div>
                </div>
                <div className="form-group text-center">
                  <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.selectWinner()}>Pick Winner</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.modalTimer} style={{width: '90%'}} id="modalTimer">
            <ModalHeader>Time To Video End {tenantVariables.doNotCollectEmail || isMlbApp ?"":"And Email Send"} (DO NOT CLOSE BROWSER)</ModalHeader>
            <ModalBody style={{padding:15}}>
              <ReactCountdownClock
                seconds={videoLength}
                color="#000"
                alpha={0.9}
                size={300}
                onComplete={()=>this.setState({modalTimer:false}, ()=>{if(!sentEmail){this.sortAndPrepareToSendEmail()}})}
              />
            </ModalBody>
            <div className="form-group text-center">
              <button className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=> this.stopVideo() }>Stop Video</button>
              <button style={{marginLeft: 10, display: tenantVariables.doNotCollectEmail || isMlbApp ? "none":""}} className="btn btn-primary btn-lg submit-button" id="submitButton" onClick={()=>this.sortAndPrepareToSendEmail()}>Send Email Now</button>
            </div>
          </Modal>
          <Modal toggle={this.toggleRacerVideo} isOpen={this.state.modalRacerVideo} style={{width: '90%'}} id="modalRacerVideo">
            <ReactPlayer playsinline={false} volume={null} url={this.state.videoLink} muted={false} playing={false} controls={true} width="100%" height="auto"/>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
